import { styled } from "linaria/react";
import Color from "color";
import { colors } from "theme";

const DEFAULT_COLOR = colors.banana;
const IconContainer = styled.div<{
  color: string | undefined;
  secondaryColor?: string | undefined;
}>`
  --secondary: ${({ secondaryColor, color = DEFAULT_COLOR }) =>
    secondaryColor ?? Color(color).rotate(2).darken(0.15).string()};
  svg {
    color: ${({ color = DEFAULT_COLOR }) => color};
    max-width: 100%;
  }
`;

export default IconContainer;
